/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function configurer(){
    
    //Anchor animations
    $('a[href^="#"]').on('click touchend', function() {
      var the_id = $(this).attr("href");
      if(the_id !== '#') {

        $('html, body').animate({
          scrollTop: $(the_id).offset().top-200
        }, 'slow');

      }
      return false;

    });

    //FOOTER
    /*var copyrightHeight = $('#footer-copyright').outerHeight();
      
    $('body').css('margin-bottom',copyrightHeight+'px');*/

    //MENU TRIGGER
    $('.menu-trigger').on('click', function() {
      $(this).toggleClass('open');
      $('#header .nav-wrapper').slideToggle();
      $('#header .nav-wrapper').toggleClass("menu-open");
    });

    //HOMEPAGE
    $('.header-slider').slick({
      arrows: false,
      dots: true,
      appendDots: $('.header-slider-navigation'),
      fade: true
    });

    //INPUT TYPE FILE
    if($(window).width() > 1024){

      $('input[type="file"]').wrap("<div class='file-wrapper'></div>");

      $('.file-wrapper').each(function() {
        var fileName = $(this).find('input[type="file"]').attr('name');

        var svg = '<svg version="1.1" width="25px" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 35.1 33.6" style="enable-background:new 0 0 35.1 33.6" xml:space="preserve"><style>.st0{fill:#e40e20}</style><path class="st0" d="M11.4 22.7H23V10.9h5.1L17.3.9 7.1 10.7h4.3z"/><path class="st0" d="M1 20.3h6.1v6.3h21v-6.3H34v12.3H1z"/></svg>';
        
        if($("html").attr('lang') == "en-US"){
          $(this).prepend('<label class="download-bt" for="'+fileName+'"><p>Add an attachment</p>'+svg+'<span>(*optionel)</span></label>');
        }else {
          $(this).prepend('<label class="download-bt" for="'+fileName+'"><p>Joindre un document</p>'+svg+'<span>(*optionel)</span></label>');
        }

        $(this).find('input[type="file"]').slideUp();
      });

      $('input[type="file"]').on('change', function() {

        var fullPath = $(this).val();

        var svgRemove = '<svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.181874 3.39532L0.604727 3.81818L2.00006 2.42285L3.39538 3.81818L3.81824 3.39532L2.42291 1.99999L3.81824 0.604666L3.39538 0.181813L2.00006 1.57714L0.604727 0.181813L0.181874 0.604666L1.5772 1.99999L0.181874 3.39532Z" fill="#E93B6F"/></svg>';

        if (fullPath) {
            var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
            var filename = fullPath.substring(startIndex);
            if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                filename = filename.substring(1);
            }

            $(this).parent().append('<div class="active-file"><p>'+filename+'</p><span class="remove">'+svgRemove+'</span></div>');
        }

        var currentInput = $(this);

        $('.active-file .remove').on('click', function() {
          currentInput.val('');
          currentInput.parent().find('.active-file').remove();
        });

      });
    }

    //CARRIERE
    $('.poste .poste-title').on('click', function() {
      var current = $(this);

      current.parent().find('.post-content').toggleClass('visible');
      current.parent().toggleClass('open');

      if(current.parent().find('.post-content').hasClass('visible')) {

        current.parent().find('.post-content').slideToggle();

        setTimeout(function(){
          current.parent().find('.post-content-wrapper').toggleClass('visible');
        }, 500);

      }else {

        current.parent().find('.post-content-wrapper').toggleClass('visible');

        setTimeout(function(){
          current.parent().find('.post-content').slideToggle();
        }, 500);

      }

      
    });

    if($('body').hasClass('page-template-page-carriere')){

      $('.postes-list .poste').each(function() {

        var posteId = $(this).attr('poste-id');
        var posteTitle = $(this).find('.poste-title h3').text();

        $('#poste').prepend('<option value="'+posteId+'">'+posteTitle+'</option>');

      });

      $('.colaction .btn-full').on('click', function() {

        var posteId = $(this).parent().parent().parent().parent().attr('poste-id');
        $('#poste option[value="'+posteId+'"]').attr("selected", "selected");

      }); 

    }

    //SERVICES
    $('.service-slider').slick({
      arrows: true,
      dots: false,
      fade: true,
    });

    $('.service-slider .slick-prev').wrap("<div class='prev-wrapper arrow-wrapper'></div>");

    $('.service-slider .slick-next').wrap("<div class='next-wrapper arrow-wrapper'></div>");  

    var initialSlideIndex = $('.service-slider').slick('slickCurrentSlide');
    var totalSlide = jQuery('.slick-slide:not(.slick-cloned)').length;


    if(initialSlideIndex == 0){
      var nextSlideIndex = 1;
      var prevSlideIndex = totalSlide-1;
    }else {

      if(initialSlideIndex == (totalSlide-1)){
        var nextSlideIndex = 0;
        var prevSlideIndex = initialSlideIndex-1;

      }else {
        var nextSlideIndex = initialSlideIndex+1;
        var prevSlideIndex = initialSlideIndex-1;
      }

    }

    var nextIconeUrl = $('.service-slide[data-slick-index="'+nextSlideIndex+'"]').find('.icone-img').attr('src');
    var prevIconeUrl = $('.service-slide[data-slick-index="'+prevSlideIndex+'"]').find('.icone-img').attr('src');


    $('.service-slider .next-wrapper').append("<img src='"+nextIconeUrl+"' width='75px' />");

    $('.service-slider .prev-wrapper').append("<img src='"+prevIconeUrl+"' width='75px' />");



    $('.service-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      
      var nextSlideIndex = getNextSlideIndex(nextSlide);
      var prevSlideIndex = getPrevSlideIndex(nextSlide);

      var prevIconeUrl = $('.service-slide[data-slick-index="'+prevSlideIndex+'"]').find('.icone-img').attr('src');
      $('.service-slider .prev-wrapper').find("img").attr('src',prevIconeUrl);

      var nextIconeUrl = $('.service-slide[data-slick-index="'+nextSlideIndex+'"]').find('.icone-img').attr('src');
      $('.service-slider .next-wrapper').find("img").attr('src',nextIconeUrl);
    });

    //Check if selected index
    if($('#selected_service').length == 1){

      var selectedServiceSlideIndex = $('#selected_service').val();

      $('.service-slider').slick('slickGoTo', selectedServiceSlideIndex);

        $('html, body').animate({
          scrollTop: $('#service-slider').offset().top-200
        }, 'slow');

    }

    $('.service-title').on('click', function() {
        var index = $(this).attr('data-slide-index');

        index = parseInt(index);

        $('.service-slider').slick('slickGoTo', index);

        $('html, body').animate({
          scrollTop: $('#service-slider').offset().top-200
        }, 'slow');
    });

    var s = skrollr.init();
    if (s.isMobile()) {
        s.destroy();
    }

  }

  function getNextSlideIndex(slideIndex) {


    var totalSlide = jQuery('.slick-slide:not(.slick-cloned)').length;

    if(slideIndex == 0){
      var nextSlideIndex = 1;
    }else {

      if(slideIndex == (totalSlide-1)){
        var nextSlideIndex = 0;

      }else {
        var nextSlideIndex = slideIndex+1;
      }

    }

    return nextSlideIndex;

  }

  function getPrevSlideIndex(slideIndex) {

    var totalSlide = jQuery('.slick-slide:not(.slick-cloned)').length;

    if(slideIndex == 0){
      var prevSlideIndex = totalSlide-1;
    }else {

      if(slideIndex == (totalSlide-1)){
        var prevSlideIndex = slideIndex-1;

      }else {
        var prevSlideIndex = slideIndex-1;
      }

    }

    return prevSlideIndex;

  }


  window.onload = configurer;

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
